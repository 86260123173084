



































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import Info from '@/components/common/Info.vue'
import ShopsTable from '@/components/shops/ShopsTable.vue'
import ShopInfo from '@/components/shops/ShopInfo.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import { ShopsListItemType } from '@/types/shops'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import SelectCommon from '@/components/common/SelectCommon.vue'
import Drawer from '@/components/common/Drawer.vue'
import ShopsCard from '@/components/mobile/shops/ShopsCard.vue'
import ShopsFilters from '@/components/mobile/shops/ShopsFilters.vue'
import { Message } from 'element-ui'
import { MembersListItemType } from '@/types/members'
import MembersDetail from '@/components/members/MembersDetail.vue'

export type ShopsFilterType = {
  STATE?: string
}

@Component({
  components: {
    ShopsFilters,
    ShopsCard,
    Drawer,
    SelectCommon,
    ShopInfo,
    EllipseCommon,
    Info,
    ShopsTable,
    FilterTags,
    MembersDetail,
  },
})
export default class Shops extends Vue {
  loading = false
  loadingInfo = false
  isAnimationEnd = false

  filter = 'Все'
  filters: TableFilterType[] = []

  order: TableOrderType | Record<string, any> = {}

  page = 1

  showMemberInfo = false
  showShopInfo = false

  filterNames = {
    id: 'Id',
    UF_NAME: 'Название',
    UF_CODE: 'Код',
    UF_CITY: 'Город',
    UF_COUNTRY: 'Страна',
    UF_REGION: 'Регион',
    UF_STREET: 'Улица',
    UF_INDEX: 'Дом',
    UF_COMPANY_NAME: 'Компания',
  }

  filterOptions = [
    {
      label: 'Все',
      value: 'Все',
    },
    {
      label: 'Топ 10',
      value: '10',
    },
    {
      label: 'Топ 20',
      value: '20',
    },
    {
      label: 'Топ 50',
      value: '50',
    },
  ]

  query = {
    isTop: 0,
    topLimit: 10,
  }
  params = {
    filter: {},
    order: {},
    offset: 0,
  }

  showShopsFilters = false
  showFilters = false

  shops = [] as ShopsListItemType[]

  get membersDetail(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  get isShops(): number {
    return this.shopsData && this.shopsData.length
  }

  get shopsData(): ShopsListItemType[] {
    return this.$store.getters['shops/shopsList']
  }

  get shopsTotal(): number {
    return this.$store.getters['shops/shopsTotal']
  }

  get shopsCount(): number {
    return this.$store.getters['shops/selectedShops']
  }

  //TODO не вся информация о магазине
  get shopsDetail(): ShopsListItemType {
    return this.$store.getters['shops/shopsDetail']
  }

  get exportShops(): TableExportType {
    return this.$store.getters['shops/exportShops']
  }

  get showedShopsCount() {
    return 10 * this.page > this.shopsTotal ? this.shopsTotal : 10 * this.page
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data) => {
      params[data.target] = data.data
    })

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  get currentFilter() {
    return this.filterOptions.find((filter) => filter.label === this.filter)
  }

  async handleShowShopInfo(id: number): Promise<void> {
    this.loadingInfo = true
    this.showShopInfo = true

    await this.$store.dispatch('shops/getShopsDetail', id)

    this.loadingInfo = false
  }

  handleCloseShopInfo(): void {
    this.showShopInfo = false
  }

  async handleShowMemberInfo(id) {
    this.showShopInfo = false

    this.showMemberInfo = true

    await this.$store.dispatch('members/getMembersDetail', id)
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  async handleChangePage(): Promise<void> {
    const tabs = document.querySelector('.shops__filter') as HTMLElement

    tabs.scrollIntoView({ behavior: 'smooth' })

    this.params.filter = this.tableFilters
    this.params.order = this.tableOrder
    this.params.offset = (this.page - 1) * 10

    if (this.currentFilter?.value) {
      this.query.isTop = 1
      this.query.topLimit = +this.currentFilter.value
    }

    if (this.currentFilter?.value && isNaN(+this.currentFilter?.value)) {
      this.query.isTop = 0
      this.query.topLimit = 10
    }

    this.loading = true

    await this.$store.dispatch('shops/getShopsList', {
      params: this.params,
      query: this.query,
    })

    this.shops = this.shopsData

    this.loading = false
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data

    this.handleChangePage()
  }

  handleAddTableFilter(data: TableFilterType): void {
    this.page = 1

    const foundFilterIndex = this.filters.findIndex(
      (filter) => filter.target === data.target
    )

    if (foundFilterIndex === -1) {
      if (data.data === '') {
        return
      }

      this.filters.push({
        data: data.data,
        target: data.target,
        name: this.filterNames[data.target],
      })
    } else {
      if (data.data === '') {
        this.handleDeleteFilter(foundFilterIndex)
      }

      this.filters.forEach((item) => {
        if (item.target === data.target) {
          this.handleDeleteFilter(foundFilterIndex)

          this.filters.push({
            data: data.data,
            target: data.target,
            name: this.filterNames[data.target],
          })
        }
      })
    }

    this.isAnimationEnd = true
  }

  lazyDownload(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.exportShops.link
    )
    link.setAttribute('download', this.exportShops.name)
    link.click()
    link.remove()
  }

  async handleExportShops() {
    await this.$store.dispatch('shops/getExportShops', {
      filter: this.tableFilters,
      order: this.tableOrder,
      query: this.query,
    })

    if (
      this.exportShops.type === 'error' ||
      this.exportShops.type === 'queue'
    ) {
      Message.error(this.exportShops.text || 'Ошибка')
    } else {
      this.lazyDownload()
    }
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  handleShowShopsFilters(): void {
    this.showShopsFilters = !this.showShopsFilters
  }

  handleSelectFilter(option: string): void {
    this.filter = option

    this.handleShowFilters()
  }

  handleSelectFilters(data: TableFilterType[]): void {
    this.handleShowShopsFilters()

    data.forEach((value) => {
      this.handleAddTableFilter(value)
    })
  }

  @Watch('page')
  onPageChange(): void {
    this.params = {
      filter: {},
      order: {},
      offset: 0,
    }

    this.query = {
      isTop: 0,
      topLimit: 10,
    }

    this.handleChangePage()
  }

  @Watch('filters')
  async onFiltersChange(): Promise<void> {
    this.loading = true

    this.params.filter = this.tableFilters
    this.params.order = this.tableOrder
    this.params.offset = (this.page - 1) * 10

    await this.$store.dispatch('shops/getShopsList', {
      params: this.params,
      query: this.query,
    })

    this.shops = this.shopsData

    this.loading = false
  }

  @Watch('filter')
  async onFilterChange(): Promise<void> {
    this.params = {
      filter: {},
      order: {},
      offset: 0,
    }

    this.query = {
      isTop: 0,
      topLimit: 10,
    }

    this.loading = true

    this.page = 1

    await this.handleChangePage()

    this.loading = false
  }

  async mounted(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('shops/getShopsList')

    this.shops = this.shopsData

    this.loading = false
  }
}



































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ShopsListItemType } from '@/types/shops'
import TableColumnHeader from '@/components/common/TableColumnHeader.vue'

@Component({
  components: {
    TableColumnHeader,
  },
})
export default class ShopsTable extends Vue {
  @Prop({ type: Array })
  shops!: ShopsListItemType[]

  @Prop({ type: Boolean })
  loading?: boolean

  handleShowShopInfo(row: { id: number }): void {
    this.$emit('show-shop-info', row.id)
  }

  handleAddFilter(filter: { data: string; target: string }): void {
    this.$emit('add-filter', { data: filter.data, target: filter.target })
  }

  handleChangeSort(data: object): void {
    this.$emit('change-sort', data)
  }
}

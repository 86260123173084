






























import { Component, Vue, Prop } from 'vue-property-decorator'
import { ShopsListItemType } from '@/types/shops'

@Component
export default class ShopsCard extends Vue {
  @Prop({ type: Object })
  shop!: ShopsListItemType

  handleShowShopDetail(): void {
    this.$emit('show-shop', this.shop.id)
  }
}

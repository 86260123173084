var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"shops-table",attrs:{"data":_vm.shops,"row-class-name":"color-dark"},on:{"row-click":_vm.handleShowShopInfo,"sort-change":_vm.handleChangeSort}},[_c('el-table-column',{attrs:{"sortable":"custom","prop":"ID","label":"Id","min-width":"80"}}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_NAME","label":"Название","min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"shops-table__name"},[_c('span',{staticClass:"color-gray"},[_vm._v(_vm._s(row.UF_NAME))])])]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_NAME","label":"Название","inputLabel":"Название"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"","min-width":"100","prop":"UF_CODE","label":"Код"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_CODE","label":"Код","inputLabel":"Код"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_COUNTRY","label":"Страна","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_COUNTRY)+" ")]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_COUNTRY","label":"Страна","inputLabel":"Страна"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_REGION","label":"Регион","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_REGION)+" ")]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_REGION","label":"Регион","inputLabel":"Регион"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_CITY","label":"Город","min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_CITY)+" ")]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_CITY","label":"Город","inputLabel":"Город"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_STREET","label":"Улица","min-width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_STREET)+" ")]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_STREET","label":"Улица","inputLabel":"Улица"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_INDEX","label":"Дом","min-width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_INDEX)+" ")]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_INDEX","label":"Дом","inputLabel":"Дом"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"UF_COMPANY_NAME","label":"Компания","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.UF_COMPANY_NAME)+" ")]}},{key:"header",fn:function(){return [_c('table-column-header',{attrs:{"type":"input","columnName":"UF_COMPANY_NAME","label":"Компания","inputLabel":"Компания"},on:{"add-filter":_vm.handleAddFilter}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }